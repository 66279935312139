#components-layout-demo-responsive .logo {
   height: 32px;
   margin: 16px;
   background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
   background: #fff;
}

.site-layout-background {
   background: #fff;
}
