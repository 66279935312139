.caja_principal {
    /* width: 100vw;
    height: 100vh; */
    /* border-radius: 2px;
    border: 1px solid black; */

    .titulo {
        padding: 10px;
        font-size: 30px;
    }

    .contenido {
        /* display: flex; */
        /* height: 90%;
        width: 100%; */
        padding: 0px 10px;

        .nueva_factura {
            /* width: 30%; */
            /* border-radius: 10px;
            border: 1px solid #c4c4c4; */
            padding: 10px;
            display: flex;
            flex-direction: column;
            overflow: auto;

            .input_v1 {
                width: 100%;
                border-radius: 10px;
                border: 1px solid;
                height: 30px;
                padding: 5px;
            }

            .divisoria {
                width: 100%;
                margin-top: 7px;
                display: flex;
                margin-bottom: 7px;
                justify-content: space-between;
            }

            .contenedor_ultimo {
                display: flex;
                justify-content: space-between;

                .ultima_caja {
                    width: 60%;
                }

                .caja_guardar {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 33%;
                    padding-top: 10px;
                    margin-top: 10px;

                    .boton_guardar {
                        width: 55%;
                        display: flex;
                        justify-content: center;
                        padding: 5px;
                        border: 4px solid gray;
                        height: 71%;
                        border-radius: 10px;
                        cursor: pointer;
                        transition: .2s ease-in-out;

                        svg {
                            width: 70%;
                            cursor: pointer;
                            fill: #66666a;
                        }
                    }

                    .boton_guardar:hover {
                        opacity: 0.7;

                    }

                    .titulo {
                        font-size: 13px;
                        padding: 2px;
                    }
                }
            }
        }

        .busqueda_factura {
            width: 70%;
            border-radius: 10px;
            border: 1px solid #c4c4c4;
            display: flex;
            flex-direction: column;

            .criterio_busqueda {
                display: flex;
                padding: 5px;

                .rango_fecha {
                    display: flex;
                    width: 30%;
                }

                .checkboxImpacted {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    input {
                        width: 100px;
                        margin-top: 7px;
                    }
                }

                .boton_buscador {
                    cursor: pointer;
                    transition: .2s ease-in-out;
                    display: flex;
                    justify-content: center;
                    width: 12%;
                    border: 1px solid gray;
                    border-radius: 5px;
                    margin-left: 5px;
                    background: #3f51b5;
                    color: white;

                    .titulo_buscador {
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        margin-right: 5px;
                    }

                    svg {
                        width: 20px;
                        fill: white;
                    }
                }

                .boton_buscador:hover {
                    opacity: 0.85;
                }
            }

            .caja_tabla {
                padding: 5px;
                flex: 1;
                display: flex;
                flex-direction: column;
                overflow: auto;
            }

            .botones_footer {
                display: flex;
                justify-content: center;
                padding: 10px;

                .boton {
                    padding: 10px;
                    text-align: center;

                    .contenedor {
                        width: 73px;
                        height: 74px;
                        border: 4px solid gray;
                        border-radius: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        transition: .2s ease-in-out;

                        svg {
                            width: 50px;
                            fill: #66666a;
                        }
                    }

                    .contenedor:hover {
                        opacity: 0.7;
                    }

                    .footer_titulo {}
                }
            }
        }
    }

    .boton_volver {
        position: fixed;
        display: flex;
        border: 2px solid white;
        padding: 10px 20px;
        border-radius: 10px;
        top: 10px;
        right: 10px;
        cursor: pointer;
        background: #3f51b5;
        color: white;
        width: 120px;
        justify-content: center;

        svg {
            width: 20px;
            fill: white;
            margin-left: 5px;
        }
    }

}